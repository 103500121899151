@import '../../../utils/extends'; // "Dziedziczymy" po extends z infosite

%cta {
  @extend %button-theme;
  color: $color-white;
  background-color: $color-gold;
  position: relative;
  overflow: hidden;
  border: none;
  -webkit-appearance: none;

  &:hover,
  .no-touch &:hover {
    background: $color-black;
    color: $color-gold;
  }

  &:focus,
  .no-touch &:focus {
    background: $color-black;
    color: $color-gold;
  }

  @include breakpoint($brake-sm) {
    @include pko-font-size($font-size-t2);
  }
}