@import '../../../layout/header'; // "Dziedziczenie" po całym header z infosite

@import '_header/banner'; // Nadpisanie styli z bannera
@import '_header/site-nav'; // Nadpisanie styli z bannera
@import '_header/login-layer'; // Nadpisanie styli z bannera

.header {
  $root: &;

  padding-bottom: 0;
  z-index: auto !important; // Muszę nadpisać style które są generowane przez któryś z mixinów

  @include breakpoint($brake-sm-max) {
    overflow: visible; // Naprawia marginesy na private banking na mobile które były popsute w ramach 115734
  }

  @include pko-header-expanded-or-sticky() {
    @include breakpoint($brake-md) {
      padding-top: 60px;
    }
    @include breakpoint($brake-lg) {
      padding-top: 90px;
    }
  }

  &--sticky,
  &--login,
  &--search,
  &--menu-open {
    @include breakpoint($brake-md) {
      padding-top: 55px;
    }
    @include breakpoint($brake-lg) {
      padding-top: 91px;
    }
  }

  &__inner {
    min-height: 55px;
    max-width: $max-width;
    margin-bottom: 0;

    .header--login & {
      max-width: 100%;
      right: 0;
    }

    @include breakpoint($brake-sm) {
      min-height: 60px;
    }

    @include breakpoint($brake-lg) {
      margin-left: auto;
      margin-right: auto;
    }

    @include pko-header-sticky() {
      right: 0;
      max-width: 100%;
      background-color: $color-black;
    }

    @include pko-header-expanded-and-sticky-but-not-menu() {
      background-color: $color-white;
    }

    @include pko-header-menu-expanded() {
      right: 0;
      background-color: $color-black;

      @include breakpoint($brake-sm-max) {
        border-bottom-width: 0;
      }

      @include breakpoint($brake-sm) {
        background-color: $color-white;
      }

      .no-touch & {
        right: 17px;
      }
    }

    @include pko-header-not-expanded() {
      border-bottom: none;
    }
  }

  //&__sticky-inner {
  //   padding-bottom: 55px;
  //}

  //&__sticky-paddington {
  //  @include breakpoint($brake-lg) {
  //    @include pko-header-expanded() {
  //      padding: 0;
  //    }
  //  }
  //}

  &__actions {
    @include breakpoint($brake-md) {
      @include pko-header-sticky() {
        margin-top: 8px;
      }
    }
  }

  &__action {
    @include pko-header-not-expanded() {
      color: $link-color-on-dark;
      
      @include pko-on-event() {
        color: $link-color;
  
        #{$root}__icon,
        .icon-drop-down {
          color: $link-color;
        }
      }
    }
  }

  // po id bo na homepage jest id uzyte
  #homepage & .breakline {
    display: none;
    //max-width: $max-width;
    //margin: 0 auto;
    //bottom: -84px;
  }
  #homepage &.header--with-tabs,  &.header--with-tabs{
    .breakline {
      @include breakpoint($brake-sm) {
        display: block;
        bottom:-84px;
        width: 100%;
        max-width:$max-width;
        margin: 0 auto;
      }
    }
  }
  @include breakpoint($brake-sm) {
    &.header--with-tabs {
      margin-bottom: 90px;

      &-big {
        margin-bottom: 78px;
      }
    }
  }

  @include breakpoint($brake-sm) {
    &-mobile-cta {
      display: none !important;
    }
  }
  @include breakpoint($brake-sm-max) {
    #{$root}--sticky & {
      &-mobile-cta {
        display: block !important;
        float: right;
        margin-top: 5px;
      }
    }
  }
}
