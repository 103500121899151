@import '../../../layout/content';

// Dostosowanie klasy content do szerokiego tła
.content {
  // Poprostu szeroka wersja contentu
  &--private-banking {
    max-width: $background-max-width;
  }

  // Wersja contentu z pomarańczowym tłem
  &--private-banking-orange {
    max-width: $background-max-width;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: $color-gold;

    @include breakpoint($brake-sm) {
      padding-bottom: 0;
    }
  }

  // Wersja contentu z tłem footerowym
  &--private-banking-black {
    max-width: $background-max-width;
    background-color: $color-gray-eleventh;
    background-position: top center;
    background-size: auto 100%;

    @include breakpoint($brake-sm) {
      background-image: url(../img/private-banking/footer.png);
    }

    .footer-bottom {
      &__prices {
        &__header {
          color: $color-gold-lightest;
        }
      }

      .bai {
        img {
          background-color: #ffffff;
          padding: 0 6px;
          max-height: 70px;
        }
      }

      .mobile-trends {
        img {
          background-color: #ffffff;
          padding: 6px;
        }
      }
    }
  }
}
