//@import '../../../../layout/_header/banner'; // "Dziedziczenie" banera z infosite zrobione jest w ramach header.scss

.banner {
  $root: &;

  @extend %break-from-wrap-paddington;

  // full height on screen ////////////////////////////////////////////////
  // (only for <= 900px resolution) ///////////////////////////////////////

  @include breakpoint(($brake-sm) (max-height 900px) (orientation landscape)) {
    // if over 900px window height, banner__slider has calculated height by JS
    //
    // (because with autoheight display-table-based content centering don't working,
    // and we don't use absolute & transform centering because it fail when content is too high
    // and should stretch the slide height)

    &:not(#{$root}--short) {
      min-height: 50.606vw;

      #{$root}__slide {
        height: 50.606vw;
      }
    }
  }

  .banner {
    &__slide {
      position: static;
      display: table;
      max-width: $max-width;
      background-color: $color-black;

      @include breakpoint($brake-sm) {
        height: 50.606vw;
        max-height: 734px;
        background-color: $color-transparent;
      }

      @include breakpoint($brake-lg) {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__content {
      @include breakpoint($brake-sm) {
        color: $color-white;
      }
    }

    &__text {
      display: inline-block;
      position: static;
      padding-top: 20px;
      padding-bottom: 30px;
      height: auto;
      width: 100%;
      transform: none;
      @include pko-z-index('banner');
      @include pko-give-padding(15px);

      @include breakpoint($brake-sm) {
        position: absolute;
        transform: translateY(-50%);
        top: 47%; // nie 50% bo cofamy odrobinę do góry
        width: auto;
        max-width: 400px;
        @include pko-give-padding(18px);
      }

      @include breakpoint($brake-md) {
        max-width: 470px;
        @include pko-give-padding(20px);
      }

      @include breakpoint($brake-lg) {
        @include pko-give-padding(0);
      }

      .cta {
        @include breakpoint($brake-sm) {
          &:hover,
          .no-touch &:hover {
            background: $color-white;
          }

          &:focus,
          .no-touch &:focus {
            background: $color-white;
          }
        }
      }
    }

    &__title {
      margin-top: 0;
      color: $text-color-head-on-dark;

      &--recommended {
        margin-top: 0 !important; // important bo w klasie którą nadpisuję też jest important
      }
    }

    &__description p {
      color: $color-white;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &__button,
    &__link {
      @extend %cta;

      @include breakpoint($brake-sm-max) {
        color: $color-gold;
        background: transparent;
        padding: 0;
        &:hover {
          text-decoration: underline;
          color: $color-white;
        }
      }

      @include breakpoint($brake-sm) {
        color: $color-white;

        &:hover,
        .no-touch &:hover {
          background: $color-white;
        }

        &:focus,
        .no-touch &:focus {
          background: $color-white;
        }
      }
    }

    &__image,
    &__image-mobile {
      overflow: hidden;
      max-height: 100%;
      max-width: 1920px;
      z-index: 1;
      vertical-align: middle;
      text-align: center;
      margin-bottom: -20px;
      margin-top: 10px;
      zoom: 1;

      @include breakpoint($brake-sm) {
        margin: 0 -15px;
        width: 66.66667%;
      }

      img {
        @include breakpoint($brake-sm) {
          //   max-height: 70vh;
          //  width: 100%;
          margin-bottom: 15px;
          padding: 25px;
        }

        @include breakpoint($brake-lg) {
          margin-bottom: 5px;
          #product & {
            max-height: none;
          }
        }
      }
    }

    &__image {
      display: none;
      @include breakpoint($brake-sm) {
        display: table-cell;
      }
    }

    &__image-mobile {
      display: block;
      background: $color-black;
      width: 100%;
      @media screen and (min-width: 576px) {
        width: 90%;
      }

      @include breakpoint($brake-sm) {
        display: none;
      }
    }
  }

  &--short {
    top: 0;
    margin-bottom: 0;

    @include breakpoint($brake-sm-max) {
      padding-bottom: 0;

      .banner__slide {
        background: $color-white;
      }

      .banner__text {
        padding-bottom: 0;
      }

      .banner__title {
        color: $color-black;
      }
    }

    .banner__text {
      @include breakpoint($brake-sm) {
        max-width: $max-width;
      }
    }

    .banner__title {
      @include breakpoint($brake-sm) {
        padding-right: 300px;
      }
    }
  }

  &--short + & {
    display: none;
  }
}
