@import '../../../utils/variables'; // "Dziedziczymy" po variables z infosite

// Private banking max background width
$background-max-width: 1920px;

// Private banking gold colors
$color-gold: #c8941e;
$color-gold-lightest: #faf8eb;
$color-gold-lightestst: #fffde9;
$color-gold-darkest: #2f2d29;

// Private banking additional grays
$color-gray-ninth: #c7c7c7;
$color-gray-tenth: #a5a5a5;
$color-gray-eleventh: #222222;

// -----------------------------------------------------------------------------
// colors - tutaj tworzymy nazwy dla popularnych waznych,
// na posdstawie tych wyzej, nie przesadzamy
// definicje i nadpisanie specjalnie dla private banking
// -----------------------------------------------------------------------------

$text-color: $color-gray-main;
$text-color-head: $color-black;
$text-color-on-dark: $color-gray-ninth;
$text-color-head-on-dark: $color-white;
$text-color-head-on-dark-footer: $color-gray-tenth;
$text-color-bold: $color-black;
$link-color: $color-gold;
$link-color-hover: $color-black;
$link-color-on-dark: $color-gray-third;
$icon-color: $color-gold;
$input-icon-color: $color-gold;
$bg-color: $color-white;
$overlay-color: $color-black;
$border-color: $color-gray-fifth;
$border-color-hover: $color-gold;
$border-color-on-dark: $color-gold-darkest;
$color-blind: $color-transparent;
$button-red-background-color: $color-gold;
