@import '../../../components/call';

.call {
  // Zmiana koloru tekstu na dole footera
  &__top {
    color: $color-gold-lightest;
  }
  // Zmiana koloru telefonow na dole footera
  &__phone {
    color: $link-color;

    @include pko-on-event() {
      color: $color-gold-lightest;
    }
  }
}
