.tfi-spread-wrapper {
  @extend %paddington;

  .tfi-snippet-no-filter__item {
    display: inline-block;
    width: 50%;
    vertical-align: top;
  }

  #chartTFINoFilter {
    margin-top: 30px;
  }
  .tfi-snippet-no-filter {
    &__delta {
      position: relative;
      @include pko-rate-arrows;
      padding-right: 20px;

      &--up {
        color: $color-green;
      }
      &--down {
        color: $color-red;
      }
    }
    &__header {
      padding-left: 0;
      padding-right: 0;
    }
  }
}


