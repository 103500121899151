.poi-private-banking {
  &__map {
    height: 40vh;
    width: 100%;
  }

  &__label {
    margin: 30px 0 0;
  }

  &__select {
    margin: 0 0 30px;
  }

  &__details {
    @extend %h4;
    margin: 30px 0;
  }
}