.contact-private-banking {
  @extend %paddington;
  margin-top: -5px; // sidebar ma taki sam styl - trzeba wyrownac bo brzydko wyglada

  &__subtitle {
    @extend %h3;
    margin: 15px 0;
    color: #fff;
  }

  @include breakpoint($brake-sm) {
    &__title {
      padding-right: 313px;
    }

    &__slide {
      height: 210px !important;
    }
  }
}