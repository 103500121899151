$large-text-width:  37.33333%;
$large-image-width: 62.66667%;

@import 'landing/header';
@import 'landing/content';
@import 'landing/footer';

@import 'landing/v1';
@import 'landing/v2';
@import 'landing/v3';
@import 'landing/v4';
@import 'landing/v5';
@import 'landing/v6';
@import 'landing/v10';