// Snippet oferty CBP występuje tylko w bankowości prywatnej

.cbp {
  $root: &;

  @extend %paddington;

  &__item {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: center;
  
    $cbp-border: 1px solid $color-gray-fifth;
  
    @include pko-on-event {
      background-color: $color-gold-lightestst;
  
      #{$root}__title {
        color: $color-gold;
      }
  
      #{$root}__text {
        color: $color-gray-main;
      }
  
      #{$root}__more {
        color: $color-gray-second;
      }
    }

    // Mobile
    width: 100%;
    padding: 50px 20px 45px;
    border-bottom: $cbp-border;

    &:last-child() {
      border-bottom: none;
    }
  
    @include breakpoint($brake-sm) {
      // Tablet
      width: 50%;
      padding: 50px 20px 45px;
      border-bottom: $cbp-border;
      border-right: $cbp-border;

      &:nth-child(2n) {
        border-right: none;
      }
      &:nth-last-child(-n+2) {
        border-bottom: none;
      }
    }
  
    @include breakpoint($brake-md) {
      // Tablet
      width: 33.333333%;
      padding: 50px 20px 45px;
      border-bottom: $cbp-border;
      border-right: $cbp-border;
      &:nth-child(2n) {
        border-right: $cbp-border;
      }
      &:nth-last-child(-n+2) {
        border-bottom: $cbp-border;
      }
      &:nth-child(3n) {
        border-right: none;
      }
      &:nth-last-child(-n+3) {
        border-bottom: none;
      }
    }
  }

  // Child elements
  &__icon {
    height: 53px;
    margin: 0 auto 25px;

    img {
      max-height: 53px;
      max-width: 64px;
    }
  }

  &__title {
    margin-bottom: 20px;
    color: $color-black;
    font-size: 21px;
  }

  &__text {
    margin-bottom: 20px;
    color: $color-gray-main;
    font-size: 13px;
  }

  &__more {
    color: $color-gold;
    font-size: 15px;
  }

  &__cta {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}