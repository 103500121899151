//@import '../../../../layout/_header/site-nav'; // "Dziedziczenie" site-nav z infosite zrobione jest w ramach header.scss

// Zmiana kolorów elementów w menu
.site-nav__main-list { // Menu pierwsze od góry
  @include pko-header-not-expanded() {
    .site-nav__link {
      color: $link-color-on-dark;

      @include pko-on-event() {
        color: $link-color;
        * {
          color: $link-color;
        }
      }
    }
    .selected > .site-nav__link {
      color: $link-color;
    }
  }

  @include pko-header-sticky() {
    // change font size when header is sticky
    @include pko-font-size($font-size-t2);
    height: 9px;
  }
}

.site-nav__sub-list { // Menu drugie od góry
  @include breakpoint($brake-sm-max)  {
    @include pko-header-menu-expanded() {
      .site-nav__link,
      .site-nav__thirdlvl span {
        color: $color-white;

        @include pko-on-event() {
          color: $link-color;
          * {
            color: $link-color;
          }
        }
      }
    }
  }

  @include pko-header-not-expanded() {
    .site-nav__link,
    .site-nav__thirdlvl span {
      color: $color-white;

      @include pko-on-event() {
        color: $link-color;
        * {
          color: $link-color;
        }
      }
    }
    .selected .site-nav__link {
      color: $link-color;
    }
  }


}

.site-nav__thirdlvl {
  @include breakpoint($brake-sm-max)  {
    width: 100%;
  }
  & > span {
    @include breakpoint($brake-sm-max)  {
      @include pko-font-size($font-size-h4-mobile);
    }
    @include pko-header-sticky() {
      // change font size when header is sticky
      @include pko-font-size($font-size-t2);
    }
  }
  & > ul {
    @include breakpoint($brake-sm-max)  {
      width: 100%;
      position: static;
      display: none;
      background: none;
      border: 0;
      padding: 0 0 0 15px;
    }
  }

  .site-nav__thirdlvl-close {
    @include breakpoint($brake-sm-max)  {
      display: none;
    }
  }
}

.site-nav__additional {
  @include breakpoint($brake-sm-max)  {
    @include pko-header-menu-expanded() {
      .site-nav__item,
      .site-nav__thirdlvl,
      .site-nav__inbox,
      .site-nav__inbox-icon,
      .site-nav__inbox-text,
      .site-nav__iko-text,
      .site-nav__mini-link,
      .call__phone,
      .social-icon {
        color: $color-white;

        @include pko-on-event() {
          color: $link-color;
          * {
            color: $link-color;
          }
        }
      }
    }
  }
}


.site-nav__select {
  @include breakpoint($brake-sm-max)  {
    @include pko-header-menu-expanded() {
      .select2-container--default .select2-selection--single {
        border-color: $color-gold;
      }
      .select2-container--default .select2-selection--single .select2-selection__arrow {
        border-color: $color-white;
      }
      .input-select--mobile + .select2-container--default .select2-selection--single .select2-selection__arrow b::before,
      .input-select--mobile + .select2-container--default .select2-selection--single .select2-selection__arrow b::after {
        color: $color-white;
      }
    }
  }
}

.site-nav__iko,
.site-nav__inbox,
.site-nav__open-account,
.site-nav__call,
.site-nav__extra-link {
  @include breakpoint($brake-sm-max)  {
    @include pko-header-menu-expanded() {
      border-width: 0;
    }
  }
}

