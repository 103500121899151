@import '../../../layout/footer';

// Przestylowanie footera
.footer {
  max-width: $max-width;
  margin-top: 0;

  // Pomarańczowy pasek nad footerem ma inna strukturę na private banking
  // i wewnątrz poniższego kontenera nie ma paddingu na dole
  .content--private-banking-orange & {
    padding-bottom: 0;
  }

  @include breakpoint($brake-lg) {
    margin-left: auto;
    margin-right: auto;
  }

  // Zmiana koloru linków w footerze
  &__list-item {
    color: $color-gold-lightest;
    .no-hover & {
      color: $color-gold-lightest;
    }
    @include pko-on-event() {
      color: $text-color-head-on-dark-footer;
    }
  }

  // Zmiana koloru linii na dole w mobile
  &__column { 
    &:last-child {
      &::after {
        border-color: $border-color-on-dark;
      }
    }
  }
}

// Zmiana kolorów linków na górze
.footer-top {
  &__link {
    color: $color-gold-lightest;

    @include pko-on-event() {
      color: $color-black;
    }
  }
}

.footer-bottom {
  // Zmiana koloru linii na dole footera
  &::before {
    background-color: $border-color-on-dark;
  }

  // Zmiana koloru tekstu na dole footera
  &__info-list {
    color: $color-gold-lightest;
  }

  // Zmiana koloru wersji jezykowej
  &__info-lang {
    color: $link-color;

    @include pko-on-event() {
      color: $color-gold-lightest;
    }
  }
  &__info-list-item {
    a {
      @include pko-on-event() {
        color: $color-gold-lightest;
      }
    }
  }
}
