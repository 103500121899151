// Standardowo w serwisie private-banking jest przygotowany styl jasny dla headera i footera
// Poniżej zmiana styli specjalnie dla ciemnego footera i headera
// Chodzi o to aby w razie potrzeby można było przełączyć się pomiędzy jedną lub drugą wersją
// Bez różnicy czy header czy footer i bez różniczy czy ciemny czy jasny

.private-banking {
  &__header,
  &__footer {
    max-width: $background-max-width;
    margin: 0 auto;
  }

  &__header {
    background-color: $color-black;

    .header__action__logo {
      img {
        -webkit-filter: contrast(0%) brightness(130%);
        filter: contrast(0%) brightness(130%);
      }
    }
  }
  &__gallery {
    width: 100%;
    position: relative;

    .owl-dot {
      &.active {
        background: $color-gold;
      }
    }

    .owl-prev,
    .owl-next {
      color: $color-gold;
    }

    .breakline {
      top: 0;
      bottom: unset;
    }
  }

  .snippet-slider-gfx__text {
    padding-top: 20px;
  }

  .site-content {
    padding-bottom: 30px;
  }

  @include breakpoint($brake-sm) {
    .snippet-slider-gfx__text {
      padding-top: 0;
    }

    .site-content {
      padding-bottom: 0;
    }
  }
  .product-menu {
    background-color: $color-black;
    border-bottom: 1px solid $color-black;

    &__list {
      li a {
        color: $link-color-on-dark;
        border-bottom: 0px;
      }

      li.active a {
        color: $link-color;
        border-bottom: 1px solid $link-color;
      }
    }
  }
  .steps__number {
    background-color: $color-gold;
  }

  .files-download {
    $root: &;

    &__icon {
      color: $color-gold;
    }
    &__link:hover {
      color: $color-gold !important;
      #{$root}__icon {
        color: $color-gray-main;
      }
    }
  }

  .tfi-snippet-no-filter__item {
    display: inline-block;
    width: 50%;
    vertical-align: top;
  }

  .header-features {
    max-width: 1242px;
    margin-left: auto;
    margin-right: auto;

    .neon-template {
      padding-bottom: 50px;

      .banner__title {
        color: $color-white;
      }

      .banner__main-link:hover .banner__title {
        color: #c8941e;
      }

      .banner__description,
      .banner__description p,
      .banner__description span {
        color: $color-white;
      }

      .banner__button {
        color: #c8941e;

        &:hover {
          color: $color-white;
        }
      }
    }
  }
}

.content--private-banking {
  .banner {
    min-height: 0;

    &__text {
      padding-top: 33px;
    }
    &__description {
      color: $color-gray-main;
    }

    .text {
      strong {
        color: $color-white;
      }

      color: $color-white;

      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $color-white;
      }
    }

    &__title {
      width: 100%;
    }

    &--static {
      padding-bottom: 0;
    }

    @include breakpoint($brake-sm) {
      &__slide {
        &--no-neon {
          height: 150px;
        }
      }
    }
  }
}
