@import '../../../components/accordion';


.content--private-banking-black {
  .accordion__header {
    border-color: $border-color-on-dark;
    color: $text-color-head-on-dark-footer;

    &:hover,
    &.active,
    &.active:hover,
    &.active:focus {
      color: $link-color;
      background-color: transparent;
    }

    &:focus {
      color: $text-color-head-on-dark-footer;
    }
  }
}




// .accordion__header {
//   border-color: $border-color-on-dark;
//   color: $text-color-head-on-dark-footer;

//   @include breakpoint($brake-xs) {
  
//     .accordion--mobile-only-footer & {
//       border-color: $border-color-on-dark;
//       color: $text-color-head-on-dark-footer;
      
//       @include pko-on-event(true) {
//         color: $link-color;
//         background-color: transparent;

//         .active {
//           color: $link-color;
//           background-color: transparent;

//           @include pko-on-event(true) {
//             color: $link-color;
//             background-color: transparent;
//           }
//         }
//       }
//     }
//   }
// }